<template>
  <v-tooltip right content-class="order-status-badge-tooltip">
    <template v-slot:activator="{ on, attrs }">
      <span class="d-flex align-center">
        {{ orderStatus }}
        <v-icon class="ml-2" v-bind="attrs" v-on="on">$info</v-icon>
      </span>
    </template>
    <span>{{ orderStatusTooltip }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: { order: { type: Object, required: true } },
  computed: {
    orderStatus() {
      return global.vm.$t(
        `order.orderStatusDescr[${this.order.orderStatusId}]`
      );
    },
    orderStatusTooltip() {
      return global.vm.$t(
        `order.orderStatusTooltip[${this.order.orderStatusId}]`
      );
    }
  }
};
</script>

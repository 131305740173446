<template>
  <div class="generic-list scroll-container">
    <CategoryTitle :category="category" />

    <v-btn-toggle
      class="my-5 rider-buttons"
      mandatory
      color="white"
      v-model="riderMissionModel"
    >
      <v-btn color="primary" depressed value="assigned">{{
        $t("rider.assignedMission.btn")
      }}</v-btn>
      <v-btn color="primary" depressed value="to_deliver">{{
        $t("rider.assignableMission.btn")
      }}</v-btn>
      <v-btn color="primary" depressed value="delivered">{{
        $t("rider.completedMission.btn")
      }}</v-btn>
    </v-btn-toggle>

    <RiderOrdersTableContainer :riderMissionModel="riderMissionModel" />
  </div>
</template>
<style lang="scss" scoped>
.rider-buttons {
  display: flex;
  flex-direction: column;
  .v-btn {
    margin: 8px 0px;
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import RiderOrdersTableContainer from "@/components/rider/RiderOrdersTableContainer.vue";

import categoryMixin from "~/mixins/category";

export default {
  name: "Rider",
  data() {
    return {
      riderMissionModel: "assigned"
    };
  },
  mixins: [categoryMixin],
  components: {
    CategoryTitle,
    RiderOrdersTableContainer
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>

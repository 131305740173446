<template>
  <div class="rider-orders-table-container">
    <template v-if="ordersList && ordersList.length > 0">
      <v-row
        no-gutters
        class="row-header text-wdy-2 py-5 grey lighten-2 default--text rounded-sm"
      >
        <v-col cols="3" sm="3" md="1" lg="1">
          <strong>{{ $t("rider.orders.header.orderNumber") }}</strong>
        </v-col>
        <v-col cols="0" sm="0" md="2" lg="2" class="hidden-sm-and-down">
          <strong>{{ $t("rider.orders.header.date") }}</strong>
        </v-col>
        <v-col cols="3" sm="3" md="2" lg="2">
          <strong>{{ $t("rider.orders.header.status") }}</strong>
        </v-col>
        <v-col cols="6" sm="6" md="2" lg="2">
          <strong>{{ $t("rider.orders.header.address") }}</strong>
        </v-col>
        <v-col cols="0" sm="0" md="2" lg="2" class="hidden-sm-and-down">
          <strong>{{ $t("rider.orders.header.pdv") }}</strong>
        </v-col>
        <v-col cols="0" sm="0" md="1" lg="1" class="hidden-sm-and-down">
          <strong>{{ $t("rider.orders.header.distance") }}</strong>
        </v-col>
        <v-col cols="0" sm="0" md="1" lg="1" class="hidden-sm-and-down">
          <strong>{{ $t("rider.orders.header.piecesNumber") }}</strong>
        </v-col>
        <v-col cols="0" sm="0" md="1" lg="1" class="hidden-sm-and-down">
          <!-- Action buttons -->
        </v-col>
      </v-row>

      <v-list class="mt-4 rider-orders-list" :key="key">
        <v-list-item
          v-for="order in ordersList"
          :key="order.orderId"
          :to="{ name: 'RiderOrderDetail', params: { orderId: order.orderId } }"
        >
          <!-- :to="'/profile/orders/' + order.orderId" -->
          <v-row
            no-gutters
            align="center"
            class="flex-sm-nowrap text-caption text-sm-body-2"
          >
            <!-- order number -->
            <v-col cols="3" sm="3" md="1" lg="1">
              <div class="font-weight-bold primary--text">
                {{ order.orderId }}
              </div>
            </v-col>
            <!-- data -->
            <v-col cols="0" sm="0" md="2" lg="2" class="hidden-sm-and-down">
              <div class="font-weight-bold">
                {{
                  $dayjs(
                    order.timeslot.date + " " + order.timeslot.beginTime,
                    "YYYY-MM-DD hh:mm:ss A"
                  ).format("D MMMM YYYY")
                }}
              </div>
            </v-col>
            <!-- stato -->
            <v-col cols="3" sm="3" md="2" lg="2">
              <OrderStatusBadge :order="order" />
            </v-col>
            <!-- indirizzo di consegna -->
            <v-col cols="6" sm="6" md="2" lg="2">
              <span v-if="order.shippingAddress.addressTypeId == 3">
                {{ order.shippingAddress.addressName }}
              </span>
              <span v-else>
                {{ order.shippingAddress.address1 }}
                {{ order.shippingAddress.addressNumber }}
                {{ order.shippingAddress.postalCode }}
                {{ order.shippingAddress.city }}
              </span>
              <br />
              <strong class="time">
                {{ $dayjs(order.timeslot.date).format("DD/MM/YYYY") }} -
                {{
                  $dayjs(
                    new Date("1970-01-01 " + order.timeslot.beginTime)
                  ).format("HH:mm")
                }}
                -
                {{
                  $dayjs(
                    new Date("1970-01-01 " + order.timeslot.endTime)
                  ).format("HH:mm")
                }}
              </strong>
            </v-col>
            <!-- punto vendita -->
            <v-col
              cols="0"
              sm="0"
              md="2"
              lg="2"
              class="hidden-sm-and-down text-capitalize"
            >
              <span v-if="order.shippingAddress.addressTypeId == 3">
                {{ order.warehouse.name }}
              </span>
              <span v-else
                >{{ order.warehouse.address.address1 }}
                {{ order.warehouse.address.addressNumber }}
                {{ order.warehouse.address.postalCode }}
                {{ order.warehouse.address.city }}
              </span>
            </v-col>
            <!-- Distanza -->
            <v-col cols="3" sm="2" md="1" lg="1" class="hidden-sm-and-down">
              <strong
                >{{ order.distance }}
                {{ $t("rider.orders.table.distance") }}</strong
              >
            </v-col>
            <!-- N° pezzi -->
            <v-col cols="0" sm="3" md="1" lg="1" class="hidden-sm-and-down">
              <strong> {{ order.itemCount }}</strong>
            </v-col>
            <!-- buttons -->
            <v-col
              cols="0"
              sm="0"
              md="1"
              lg="1"
              class="orders-list-action-container d-flex justify-space-between justify-sm-end mt-6 mt-sm-0 hidden-sm-and-down"
            >
              <v-btn
                v-if="riderMissionModel === 'assigned'"
                color="primary"
                depressed
                @click.prevent.stop="deliveredOrder(order)"
              >
                {{ $t("rider.orders.table.deliveredBtn") }}
              </v-btn>
              <v-btn
                v-if="riderMissionModel === 'to_deliver'"
                color="primary"
                depressed
                @click.prevent.stop="assignOrder(order)"
              >
                {{ $t("rider.orders.table.assignBtn") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>

      <ResponseMessage :response="responseMessage" />
    </template>
    <div v-else-if="!loading">
      {{ $t("rider.orders.noOrders") }}
    </div>
  </div>
</template>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge.vue";
import ResponseMessage from "@/components/ResponseMessage";

import baskoCustomService from "@/service/baskoCustomService";

export default {
  name: "RiderOrdersTableContainer",
  components: {
    OrderStatusBadge,
    ResponseMessage
  },
  props: {
    riderMissionModel: { type: String, required: true }
  },
  data() {
    return {
      ordersList: [],
      loading: true,
      key: 0,
      responseMessage: {}
    };
  },
  computed: {},
  methods: {
    async fetchRiderMissions() {
      try {
        this.loading = true;
        this.key++;
        this.ordersList = [];
        let res = await baskoCustomService.getRiderListOrders(
          this.riderMissionModel
        );

        if (res && res.orders) {
          this.ordersList = res.orders;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async deliveredOrder(order) {
      this.responseMessage = {};
      if (order && order.orderId) {
        let res = await baskoCustomService.deliveredOrder(order.orderId);
        if (res && res.status == 1) {
          this.responseMessage = res;
        } else {
          this.fetchRiderMissions();
        }
      }
    },
    async assignOrder(order) {
      this.responseMessage = {};
      if (order && order.orderId) {
        let res = await baskoCustomService.assignOrder(order.orderId);
        if (res) {
          this.fetchRiderMissions();
        }
      }
    }
  },
  mounted() {
    this.fetchRiderMissions();
  },
  watch: {
    riderMissionModel() {
      this.fetchRiderMissions();
    }
  }
};
</script>
